.heading-margin-btm {
    margin-bottom: 30px;
}

.paragrap-according {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #919191;
    margin-top: 0px;
}
.according-content {
  padding: 15px 21px 0px ;
}

.priv-policy-head {
    margin: 10px 0px 10px;
}

.priv-policy-head h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.686111px;
    color: #404B63;
    margin: 0px;
    text-align: center;
}

.main-heading h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.686111px;
    color: #404B63;
    margin: 5px 0px 10px;
}

.privacy-paragraph p {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
}

@media (max-width:1279px) {
    .heading-margin-btm {
        margin-bottom: 20px;
    }

    .according-content {
        padding: 5px 7px 0px;
    }

    .paragrap-according {
        font-size: 14px;
        line-height: 28px;
    }

    .priv-policy-head h2 {
        font-size: 21px;
        line-height: 27px;
    }

    .main-heading h2 {
        font-size: 21px;
        line-height: 28px;
        margin: 5px 0px 7px;
    }

    .privacy-paragraph p {
        font-size: 13px;
        line-height: 28px;
        margin-top: 0px;
        margin-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .priv-policy-head {
        margin: 0px 0px 10px;
    }

    .priv-policy-head h2 {
        font-size: 19px;
    }

    .main-heading h2 {
        font-size: 18px;
    }

    .mar-top-sm {
        margin-top: 25px;
    }
}