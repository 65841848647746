// @font-face {
//     font-family: 'Flexo';
//     src: url('fonts/Flexo-Medium.eot');
//     src: local('Flexo Medium'), local('Flexo-Medium'),
//         url('fonts/Flexo-Medium.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Flexo-Medium.woff2') format('woff2'),
//         url('fonts/Flexo-Medium.woff') format('woff'),
//         url('fonts/Flexo-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Flexo';
//     src: url('fonts/Flexo-Bold.eot');
//     src: local('Flexo Bold'), local('Flexo-Bold'),
//         url('fonts/Flexo-Bold.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Flexo-Bold.woff2') format('woff2'),
//         url('fonts/Flexo-Bold.woff') format('woff'),
//         url('fonts/Flexo-Bold.ttf') format('truetype');
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flexo';
//     src: url('fonts/Flexo-Regular.eot');
//     src: local('Flexo Regular'), local('Flexo-Regular'),
//         url('fonts/Flexo-Regular.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Flexo-Regular.woff2') format('woff2'),
//         url('fonts/Flexo-Regular.woff') format('woff'),
//         url('fonts/Flexo-Regular.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flexo';
//     src: url('fonts/Flexo-Demi.eot');
//     src: local('Flexo DemiBold'), local('Flexo-Demi'),
//         url('fonts/Flexo-Demi.eot?#iefix') format('embedded-opentype'),
//         url('fonts/Flexo-Demi.woff2') format('woff2'),
//         url('fonts/Flexo-Demi.woff') format('woff'),
//         url('fonts/Flexo-Demi.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
// }

body{
    margin: 0px;
    font-family: 'Nunito Sans', sans-serif;
}
