// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }
  
.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: flex;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.menu-items > a .dot-before {
  margin-left: 3px;
}

.menu-items > a {
  text-align: left;
  padding: 0px 59px 0px 0px;
  font-size: 13px;
  line-height: 16px;
  color: #FFF;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.nav-menu .menu-items:last-child > a, .nav-menu .menu-items:last-child button {
  padding-right: 0px;
}
.menu-items a:hover {
  color: #AA8453 !important;
  background-color: transparent;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

.dot-before {
  background-color: #AA8453;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin-right: 8px;
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.cursor{
  cursor: pointer;
}

.menu-items > a:hover .dot-before {
  opacity: 1;
}
@media(min-width: 1280px) and (max-width: 1440px){
  .menu-items > a {
    padding: 0px 38px 0px 0px;
    font-size: 13px;
  }
}
@media(min-width: 1600px){

}
