.navbar-transparent-fixed {
    background: transparent !important;
    box-shadow: none !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    border-bottom: 1px solid transparent;
    height: 94px;
}

.logo {
    position: absolute;
    top: 27px;
    left: 39px;
}

.MuiBadge-colorPrimary {
    background-color: rgba(170, 132, 83, 0.80) !important;
}

.display-emp button {
    background: #978667 !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    color: #fff !important;
    line-height: 16px !important;
    height: 37px !important;
    display: inline-block !important;
    padding: 0px 20px !important;
    text-decoration: none !important;
    font-family: 'Nunito Sans', sans-serif;
}



// .call-now {
//     margin-right: 20px;
// }

.call-now .display-emp button {
    color: #978667 !important;
    background: transparent !important;
    border: 1px solid #978667 !important;
    box-shadow: none;
}

.display-emp-mob {
    padding: 20px 20px 0;
    width: 100%;
}

.display-emp-mob button {
    width: 100%;
    background: #978667 !important;
    border-radius: 50px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    color: white !important;
    line-height: 16px !important;
    height: 50px !important;
    display: inline-block !important;
    padding: 16px 37px !important;
    text-decoration: none !important;
}

.navbar-white-fixed {
    background: #FFF !important;
    box-shadow: 0 0 14px rgba(0, 0, 0, .09) !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
}

.navbar-white-fixed .menus>.menu-items>a {
    color: #000 !important;
}

.navbar-white-fixed .menus>.menu-items>a:hover {
    color: #E6B022 !important;
}

.navbar-inner {
    width: auto;
}

.navbar-center {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px;
    height: 94px;
}

.profile-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0;
    position: absolute;
    bottom: 70px;
    width: 100%;
}
.profile-nav button {
    background: transparent !important;
    border-radius: 12px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    color: white !important;
    line-height: 16px !important;
    height: 36px !important;
    display: inline-block !important;
    padding: 0px 0px !important;
    text-decoration: none !important;
    border: 1px solid #FFF;
    width: 125px;
}
.profile-nav a + a {
    margin-left: 26px;
}
.profile-nav .callnw {
    background: #fff !important;
    color: #AA8453 !important;
    border: 1px solid #FFF !important;
}

.search-icon-header {
    position: relative;
    top: 2px;
}

.sidebar-list-text span {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #fff;
    position: relative;
}
.sidebar-list-text.active span:before {
    content: "";
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background: #AA8453;
    display: block;
    position: absolute;
    left: 0px;
    top: 100%;
}
.sidebar-list-text:hover span {
    color: #AA8453;
}

.sidebar-collapse {
    background: rgba(14, 151, 255, 0.04);
}

.subitem-pad-left {
    padding-left: 20px;
}

.sidebar-drawer {
    width: 100%;
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: -20%;
}
.list-item-sidebar {
    text-align: center !important;
    justify-content: center !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.sidebar-list-text span {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #fff;
    position: relative;
}
.emp-corner-mobile {
    margin: 8px auto 0px;
    border-top: 1px solid #e0dada;
    width: 100%;
    padding: 20px 20px 0px;
    box-sizing: border-box;
}

.emp-corner-mobile button {
    background: rgb(14, 151, 255) !important;
    border-radius: 10px !important;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    display: block !important;
    margin: 0px 0px !important;
    width: 100% !important;
    text-align: center !important;
    box-shadow: none !important;
    padding: 9px 8px !important;
}
.left-social-mobile {
    position: absolute;
    bottom: 0px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}
.left-social-mobile ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0px 0px 0px;
    padding: 10px 0px;
    justify-content: center;
}
.left-social-mobile ul li {
    margin-right: 10px;
    margin-bottom: 10px;
}
.left-social-mobile ul li svg path {
    fill: #fff !important;
}
.left-social-mobile ul li:last-child {
    margin-right: 0px;
}

.login-btn {
    background-color: rgb(0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    padding: 9px 18px 4px !important;
    border-radius: 0px !important;
    border: 1px solid rgb(255, 255, 255) !important;
}

.login-btn:hover {
    background-color: #0E97FF !important;
    border: 1px solid #0E97FF !important;
}

.top-bar-contct {
    background: #1B2132;
    padding: 12px 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 2;
}

.top-bar-contact-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    align-items: center;
    list-style-type: none;
}

.top-bar-contact-list li {
    margin-right: 20px;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: initial;
}

.top-bar-contact-list li:last-child {
    margin-right: 0px !important;
}

.top-bar-contact-list .top-bar-contct-icon {
    margin-right: 10px;
}

.position-fixed {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 5;
}

.header-navbar-subpage {
    border-bottom: 1px solid rgb(14, 151, 255, 0.20);
    box-shadow: 0px 0px 14px rgb(14, 151, 255, 0.09) !important;
}

.sub-page-top-header-spacer {
    height: 138px;
}

.header-navbar-subpage-top {
    background-color: #FFFFFF !important;
    box-shadow: none !important;
    position: fixed !important;
    left: 0px !important;
    top: 45px !important;
    width: 100% !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    z-index: 50 !important;
}

.header-navbar-subpage-top .navbar-center {
    padding: 12px 0px 8px;
}

.header-navbar-subpage-top-fixed {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05) !important;
}

.header-navbar-subpage .display-emp button {
    background: #FFFFFF !important;
    border: 1px solid #D8DCDF !important;
    box-shadow: none !important;
}

.drawer-outer {
    padding-left: 20px;
}

.drawer-logo {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.5rem;
}

.drawer-logo img {
    width: 120px;
}
.cross-header-drawer {
    position: absolute;
    right: 20px;
    top: 28px;
    border: none;
    background: transparent;
}
.cart-modal {
    display: flex;
    padding: 15px 41px 15px 0px;
    position: relative;
    border-top: 1px solid grey;
    margin: 10px 0px 10px;
}


.hr-cart {
    background: #DCE1E5;
    margin: 25px 0px 25px;
}

.heading-title {
    color: #232631;
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    line-height: 149.023%;
    padding: 0px 0px 0px 0px;
}

.cart-count {
    margin-left: 10px;
    color: #232631;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.cart-image-style {
    margin-right: 15px;
    min-width: 75px;
    height: 75px !important;
    border-radius: 10px;
    object-fit: cover;
}

.hotel-title {
    color: #232631;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0px;
}


.digit-color {
    color: #AA8453;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0px;
}

.checkout-btn {
    width: 322px;
    height: 48px;
    border-radius: 12px;
    background: #AA8453;
    border: none;
    margin: 0px 0px 0px 0px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;

}

.view-cart-btn {
    width: 322px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #AA8453;
    background: #F3F8FC;
    margin: 15px 0px 20px 0px;
    color: #AA8453;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;

}

.rs-style {
    color: #000;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.delete-icon-rel {
    position: relative;
    position: absolute;
    top: 20px;
    right: 7px;
    cursor: pointer;
}

.cart-div {
    position: fixed;
    top: 95px;
    right: 64px;
    width: 372px;
    background: #F3F8FC;
    border: 1px solid #DCE1E5;
    border-radius: 22px;
    z-index: 200;
    padding: 2px 25px 14px;
    box-sizing: border-box;
    cursor: pointer;

}
.norooms-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 334px;
    flex-flow: column;
}
.norooms-cart h5 {
    color: #232631;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 7px 0px 12px;
}
.norooms-cart button {
    color: #AA8453;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid #AA8453;
    background: transparent;
    padding: 9px 20px;
    cursor: pointer;

}
.circle-color {
    color: white;
}

.header-right {
    display: flex;
}

.header-right ul {
    display: flex;
    list-style-type: none;
    padding: 0px;
    align-items: center;
}

.header-right ul li {
    margin-right: 38px;
}

.header-right ul li:last-child {
    margin-right: 0px;
}

.profile-menu {
    width: 200px;
    border-radius: 10px;
    background: #F3F8FC;
    position: absolute;
    right: 10px;
    top: 94px;
    padding: 14px 25px 14px 25px;
    cursor: pointer;
}

.main-ul-div {
    list-style-type: none;
    padding: 27px 0px 0px 0px;
    margin: 0px;
}
.list-style-menu {
    margin-bottom: 14px;
    border-bottom: 1px solid #e1e8ed;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}
.list-style-menu span {
    color: #232631;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px 0px 0px 16px;
    margin-bottom: 0px;
}


.arrow-icon-style {
    color: black;
    position: absolute;
    top: 33px;
    cursor: pointer;

}

.profile-drop-title {
    display: flex;
    align-items: center;
}
.profile-drop-title .button-prfileineer {
    padding: 0px;
}

.profile-drop-title-left {
    display: flex;
    flex-flow: column;
    margin-left: 10px;
}

.profile-drop-title-left span {
    color: #232631;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}


.profile-mail {
    color: #4D4D4D !important;
    font-size: 12px !important;
}


@media (max-width: 1280px) {
    .top-bar-contct {
        display: none;
    }

    .cart-res {
        position: absolute !important;
        right: 30px;
        top: 22px;
    }

    .sub-page-top-header-spacer {
        height: 74px;
    }

    .header-navbar-subpage-top {
        top: 0px !important;
    }

    .navbar-center {
        padding: 11px 0px 0px !important;
    }

    .display-emp {
        display: none;
    }

    .contact-bar-off {
        display: none !important;
    }

    .cart-div {
        position: fixed;
        top: 56px;
        right: 9px;
        width: 358px;
    }

    .delete-icon-rel {
        right: 17px;
    }
}

@media (max-width: 1280px) {
    .navbar-center {
        height: 50px;
    }
    .burger-toggle .burgerbutton {
        min-width: 40px;
        left: auto;
        padding: 0px;
        position: absolute;
        right: 16px;
        top: 29px;
        padding: 0px;
    }
    .cart-res {
        position: absolute !important;
        right: 66px;
        top: 22px;
    }
}

@media(max-width: 1199px) {
    .navbar-transparent {
        background: rgba(0, 0, 0, .69) !important;
        box-shadow: none !important;
    }

    .login-btn {
        font-size: 12px !important;
        padding: 9px 12px 6px !important;
    }
}

@media (min-width: 1400px) {
    .MuiContainer-maxWidthLg {
        max-width: 1320px !important;
    }
}

.bar-width {
    max-width: 100% !important;
}